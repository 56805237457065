
import { defineComponent, watch } from "vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import apiService from "@/core/services/ApiService";

export default defineComponent({
  name: "reset-survey",
  props: {
    id: {
      required: true,
    },

    check: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  emit: ["modal-value"],
  setup: function (props, { emit }) {
    watch(
      () => props.check,
      () => {
         swalAlert(props.id);
      }
    );

    function swalAlert(id) {
      Swal.fire({
        title: `Are you sure reset this assessment?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      }).then((result) => {
        if (result.isConfirmed) {
          resetSurvey(id);
        }
      });
    }
    // todo ban avelacneluc paginationy gnuam araji ej u  sax datanery petqa desc linen
    function resetSurvey(id) {
      apiService.delete(`/reset/survey/${id}`).then((resp) => {
        let data = {
          status: "update",
          data: resp.data.data,
        };
        emit("modal-value", data);
        Swal.fire("Reset!", "Assessment was successfully reset.", "success");
      });
    }
  },
});
