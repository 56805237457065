import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTable = _resolveComponent("UserTable")!
  const _component_Compare = _resolveComponent("Compare")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.userID)
      ? (_openBlock(), _createBlock(_component_UserTable, {
          key: 0,
          onCheckboxValue: _ctx.checkboxVal,
          "deg-changed": _ctx.changedDeg,
          deg: _ctx.newVal,
          id: _ctx.userID
        }, null, 8, ["onCheckboxValue", "deg-changed", "deg", "id"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Compare, {
      "survey-changed": _ctx.changed,
      survey: _ctx.surveyID,
      onUpdatedCheckbox: _ctx.newCheckbox
    }, null, 8, ["survey-changed", "survey", "onUpdatedCheckbox"])
  ], 64))
}