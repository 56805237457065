
import { computed, defineComponent, onMounted, ref } from "vue";
import UserTable from "@/components/widgets/tables/UserTable.vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import Compare from "@/components/Compare.vue";

export default defineComponent({
  name: "home",
  components: {
    UserTable,
    Compare,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Home");
    });
    const store = useStore();
    const surveyID = ref();
    const changed = ref(true);
    const changedDeg = ref(true);
    const newVal = ref();
    const checkboxVal =  (resp) => {
      changed.value ? changed.value = false : changed.value = true;
      surveyID.value =  resp;
    }
    const newCheckbox =  (resp) => {
      changedDeg.value ? changedDeg.value = false : changedDeg.value = true;
      newVal.value =  resp;
    };
    const userID = computed(() => {
      return store.getters.currentUser.id;
    });
    return {
      userID,
      surveyID,
      changed,
      changedDeg,
      newVal,
      checkboxVal,
      newCheckbox,
    };
  },
});
