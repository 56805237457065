import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = {
  class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
  role: "row"
}
const _hoisted_3 = ["data-td", "onClick"]
const _hoisted_4 = { class: "fw-bold text-gray-600" }
const _hoisted_5 = {
  key: 1,
  class: "odd"
}
const _hoisted_6 = {
  colspan: "8",
  class: "dataTables_empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", {
      class: _normalizeClass([[_ctx.loading && 'overlay overlay-block'], "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"]),
      id: "global-table",
      ref: "tableRef"
    }, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
            return (_openBlock(), _createElementBlock("th", {
              key: i,
              "data-td": cell.name,
              onClick: ($event: any) => (
                _ctx.sort(
                  cell.sortingField ? cell.sortingField : cell.key,
                  cell.sortable
                )
              ),
              class: _normalizeClass([
                cell.name && 'max-w-125px',
                cell.sortable !== false && 'sorting',
                _ctx.tableHeader.length - 1 === i && 'text-end',
                _ctx.currentSort ===
                  `${cell.sortingField ? cell.sortingField : cell.key}desc` &&
                  'sorting_desc',
                _ctx.currentSort ===
                  `${cell.sortingField ? cell.sortingField : cell.key}asc` &&
                  'sorting_asc',
              ]),
              tabindex: "0",
              rowspan: "1",
              colspan: "1",
              style: {"cursor":"pointer"}
            }, _toDisplayString(cell.name), 11, _hoisted_3))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", _hoisted_4, [
        (_ctx.data && _ctx.data.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: index,
                class: "odd item-tr"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: i,
                    class: _normalizeClass({ 'text-end': _ctx.tableHeader.length - 1 === i })
                  }, [
                    _renderSlot(_ctx.$slots, `cell-${cell.key}`, { row: item }, () => [
                      _createTextVNode(_toDisplayString(item[cell.key] && item[cell.key].toString().length > 100
                        ? item[cell.key].toString().substring(0, 100) + " ..."
                        : item[cell.key]), 1)
                    ])
                  ], 2))
                }), 128))
              ]))
            }), 128))
          : _createCommentVNode("", true),
        (_ctx.data.length === 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.emptyTableText), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}