
import { defineComponent, watch } from "vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import apiService from "@/core/services/ApiService";

export default defineComponent({
  name: "copy-survey",
  props: {
    id: {
      required: true,
      type: Number,
    },
    check: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  emit: ["modal-value"],
  setup: function (props, { emit }) {
    watch(
      () => props.check,
      () => {
         swalAlert(props.id);
      }
    );

    function swalAlert(id) {
      Swal.fire({
        title: `Are you sure copy this assessment?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, copy it!",
      }).then((result) => {
        if (result.isConfirmed) {
          resetSurvey(id);
        }
      });
    }

    function resetSurvey(id) {
      apiService
        .get(
          "/copy/survey",
          `${id}?perPage=${props.perPage}&page=${props.page}`
        )
        .then((resp) => {
          let data = {
            status: "insert",
            data: resp.data,
          };
          emit("modal-value", data);
          Swal.fire(
            "Replicated!",
            "Assessment was successfully replicated.",
            "success"
          );
        });
    }
  },
});
