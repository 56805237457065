
import { defineComponent, onMounted, ref, watch } from "vue";
import apiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ScoreChart from "@/components/ScoreChart.vue";
import { array } from "yup";

export default defineComponent({
  name: "Compare",
  components: { ScoreChart },
  props: {
    survey: {
      required: true,
      type: Number,
    },
    surveyChanged: {
      required: true,
    },
  },
  emit: ["updated-checkbox"],
  setup: function (props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    let surveyID = ref();
    let arrayCompare = ref<any>([]);
    let arrayIndex = ref<any>([]);
    let data = ref();
    let data2 = ref(null);
    let data3 = ref(null);
    watch(
      () => props.surveyChanged,
      () => {
          arrayCompare.value[props.survey] = props.survey;
          if (!data2.value) {
            arrayIndex.value[props.survey] = "data2";
          } else {
            arrayIndex.value[props.survey] = "data3";
          }
          getResults(props.survey, "compare");

      }
    );
    onMounted(() => {
      lastSurvey();
    });
    const removeCompare = (id) => {
      if (arrayIndex.value[id] == "data2") {
        data2.value = null;
      } else if (arrayIndex.value[id] == "data3") {
        data3.value = null;
      }
      emit("updated-checkbox", id);

    };
    async function getResults(id, No) {
      apiService
        .get(`score/survey/${id}`)
        .then(
          await function (resp) {
            if (resp.data.data) {
              if (No === "main") {
                data.value = resp.data.data;
              } else if (No === "compare") {
                if (!data2.value) {
                  resp.data.data["survey_id"] = id;
                  data2.value = resp.data.data;
                } else {
                  resp.data.data["survey_id"] = id;
                  data3.value = resp.data.data;
                }
              }
            }
          }
        )
        .catch(function (error) {
          if (error.response && error.response.status === 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    async function lastSurvey() {
      apiService
        .get(`score/last-survey`)
        .then(
          await function (resp) {
            getResults(resp.data.id, "main");
          }
        )
        .catch(function (error) {
          if (error.response && error.response.status === 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    return {
      data,
      data2,
      data3,
      removeCompare,
    };
  },
});
