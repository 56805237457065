
import {defineComponent, onMounted, ref, watch} from "vue";
import apiService from "@/core/services/ApiService";
import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";
import { useRouter } from "vue-router";
import ResetSurvey from "@/components/swal/ResetSurvey.vue";
import CopySurvey from "@/components/swal/CopySurvey.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "survey_table",
  components: { GlobalTable, ResetSurvey, CopySurvey },
  props: {
    id: {
      required: true,
      type: Number,
    },
    deg: {
      required: true,
      type: Number,
    },
    degChanged: {
      required: true,
    },
  },
  emit: ["checkbox-value"],
  setup: function (props, { emit }) {
    const loading = ref<boolean>(false);
    const checkReset = ref<boolean>(false);
    const checkCopy = ref<boolean>(false);
    const resetID = ref(null);
    const currentSort = ref<string>("");
    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 1,
    });
    const router = useRouter();
    let page = ref<number>(1);
    let perPage = ref<number>(10);
    let data = ref<any>([]);
    let arrayCompare = ref<any>([]);
    watch(
        () => props.degChanged,
        () => {
          let index = arrayCompare.value.indexOf(Math.abs(props.deg));
          if (index !== -1) {
            arrayCompare.value.splice(index, 1);
           /* if(arrayCompare.value.length === 0){
            }*/
          }
        }
    );
    onMounted(() => {
      getSurveys(perPage.value, page.value);
    });
    const tableHeader = [
      {
        name: "id",
        key: "id",
        sortable: false,
      },
      {
        name: "Date Started",
        key: "date",
        sortable: false,
      },
      {
        name: "Sections Done",
        key: "section",
        sortable: false,
      },
      {
        name: "Comment",
        key: "comment",
        sortable: false,
      },
      {
        name: "Details",
        key: "details",
        sortable: false,
      },
      {
        name: "Copy",
        key: "copy",
        sortable: false,
      },
      {
        name: "Complete",
        key: "complete",
        sortable: false,
      },
      {
        name: "Reset",
        key: "reset",
        sortable: false,
      },
      {
        name: "Select",
        key: "select",
        sortable: false,
      },
    ];

    const newSurvey = () => {
      let userID = props.id;
      page.value = 1;
      let form = {
        user_id: userID,
        perPage: perPage.value,
        page: page.value,
      };
      apiService
        .post("/survey", form)
        .then((resp) => {
          if (resp.data.data) {
            Swal.fire({
              text: `The assessment has been successfully created!`,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              data.value = resp.data?.data;
            //  pagination.value.page = resp.data?.meta.current_page;
            //  pagination.value.total = resp.data?.meta.total;
            //  pagination.value.rowsPerPage = resp.data?.meta.per_page;
              loading.value = false;
            });
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    const getID = (value) => {

if(!arrayCompare.value.includes(value)){
  arrayCompare.value.push(value);
  emit("checkbox-value", value);
}else{
  Swal.fire("Message", 'The survey is already in the compare section', "warning");
}

    };

    function getSurveys(perPage, page) {
      loading.value = true;
      apiService
        .get(`/user/${props.id}/details`, `?perPage=${perPage}&page=${page}`)
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            data.value[0]["last"] = 1;
          //  pagination.value.page = resp.data?.meta.current_page;
          //  pagination.value.total = resp.data?.meta.total;
          //  pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    const resetSurvey = (id) => {
      checkReset.value = !checkReset.value;
      resetID.value = id;
    };

    const copySurvey = (id) => {
      checkCopy.value = !checkCopy.value;
      resetID.value = id;
    };
    const setItemsPerPage = (event) => {
      page.value = 1;
      perPage.value = event.target.value;
      getSurveys(perPage.value, page.value);
    };

    const currentPageChange = (val) => {
      page.value = val;
      pagination.value.page = val;
      getSurveys(perPage.value, val);
    };
    const checkModal = (resp) => {
      if (resp.status == "update") {
        loading.value = true;
        let array = JSON.parse(JSON.stringify(data.value));
        let foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
        loading.value = false;
      } else if (resp.status == "deleted" || resp.status == "insert") {
       // pagination.value.page = resp.data?.meta.current_page;
       // pagination.value.total = resp.data?.meta.total;
       // pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };
    return {
      loading,
      tableHeader,
      data,
      setItemsPerPage,
      currentPageChange,
      pagination,
      perPage,
      page,
      currentSort,
      newSurvey,
      resetSurvey,
      checkReset,
      resetID,
      checkModal,
      copySurvey,
      checkCopy,
      getID,
      arrayCompare,
    };
  },
});
